<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const swiperContainer = ref()

const swiperParams = {
  effect: 'fade',
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   initializeSlider()
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <div class="swiper swiper--process">
    <swiper-container init="false" ref="swiperContainer" events-prefix="swiper">
      <div slot="container-end">
        <div class="swiper-navigation">
          <div class="swiper-button swiper-button-prev sensible">
            <div class="btn btn--round btn--round-dark">
              <svg class="icon icon--btn-round icon--rotate-180">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
          <div class="swiper-button swiper-button-next sensible">
            <div class="btn btn--round btn--round-dark">
              <svg class="icon icon--btn-round">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <swiper-slide class="swiper-slide" v-for="(slide, index) in props.slides">
        <div class="slide-process">
          <div class="slide-content">
            <div class="slide-number">0{{ index + 1 }}</div>
            <h3 class="slide-title">{{ slide.title }}</h3>
            <div class="slide-description" v-html="slide.description"></div>
          </div>
          <div class="slide-image">
            <SharedBasicImage
              :assets="slide.assets"
              :alternative="slide.title"
              class="swiper-slide-image" />
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style lang="scss">
.swiper--process {
  position: relative;

  .swiper-navigation {
    .swiper-button-prev {
      margin-right: pxrem(8);
    }
  }

  .swiper-slide {
    height: auto;
  }

  .slide-process {
    background-color: $color-gray-light;
    display: flex;
    flex-direction: column;
    height: 100%;

    .slide-content {
      display: flex;
      flex-direction: column;

      @include mq(md) {
        padding: col-width(1);
      }
    }

    .slide-number {
      color: $color-gray-dark;
      font-size: $font-xxlarge;
      margin-bottom: pxrem(25);

      @include mq(md) {
        font-size: $font-xxxlarge;
        margin-bottom: pxrem(45);
      }
    }

    .slide-title {
      @include display-2;
      font-weight: 400;
      margin-bottom: pxrem(15);
      margin-top: auto;
    }

    .slide-description {
      @include paragraph;

      p {
        margin-top: 0;
      }
    }

    .slide-image {
      margin-top: auto;
    }

    .swiper-slide-image {
      aspect-ratio: 1.58;

      @include mq(lg) {
        aspect-ratio: 3.88;
      }

      img {
        aspect-ratio: 1.58;
        width: 100%;

        @include mq(lg) {
          aspect-ratio: 3.88;
        }
      }
    }
  }

  swiper-container {
    height: auto;
    margin-top: pxrem(20);

    @include mq(md) {
      margin-top: 0;
    }
  }

  .swiper-navigation {
    margin-bottom: pxrem(15);
    margin-top: pxrem(15);

    @include mq(md) {
      margin-bottom: 0;
      margin-top: 0;
      position: absolute;
      right: col-width(1);
      top: col-width(1);
      z-index: 1;
    }
  }
}
</style>
