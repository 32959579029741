<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

// Function to get the path from a URL
function getQueryStringPath(url) {
  const [path, _] = url.split('?')
  return path
}

// Function to get query string parameters as a single string from a URL
function getQueryStringParameters(url) {
  const [, queryString] = url.split('?')
  if (queryString) {
    const keyValuePairs = queryString.split('&')
    return keyValuePairs.join('&')
  }
  return ''
}
</script>

<template>
  <transition>
    <section class="paragraph paragraph--areas">
      <div class="paragraph-bg">
        <picture class="paragraph-image animate-in-fade animate-out-fade">
          <source
            v-for="asset in filterPictureSourceTags(content.assets)"
            :srcset="asset.src"
            :type="asset.format"
            :media="asset.media" />
          <img
            v-for="asset in filterPictureImageTag(content.assets)"
            :srcset="asset.src"
            :alt="content.title + ' - Pininfarina'" />
        </picture>
      </div>
      <div class="paragraph-wrapper">
        <div class="paragraph-elem paragraph-elem--title">
          <h2 class="paragraph-title animate-in-bottom animate-out-right">
            {{ content.title }}
          </h2>
        </div>
        <NuxtLink
          class="paragraph-elem paragraph-elem--link sensible animate-in-fade-late animate-out-right"
          v-for="area in content.areas"
          :to="
            localePath(getQueryStringPath(area.url)) +
            '?' +
            getQueryStringParameters(area.url)
          ">
          <picture class="paragraph-area-image" v-if="area.assets">
            <source
              v-for="asset in filterPictureSourceTags(area.assets)"
              :srcset="asset.src"
              :type="asset.format"
              :media="asset.media" />
            <img
              v-for="asset in filterPictureImageTag(area.assets)"
              :srcset="asset.src"
              :alt="content.title + ' - Pininfarina'" />
          </picture>
          <div class="area-button btn btn--round-small btn--round-light">
            <svg class="icon icon--btn-round-tiny">
              <use xlink:href="#icon-dot"></use>
            </svg>
          </div>
          <div class="area-label">
            {{ area.label }}
          </div>
        </NuxtLink>
      </div>
    </section>
  </transition>
</template>

<style lang="scss">
.paragraph--areas {
  margin-bottom: pxrem(50);
  margin-top: pxrem(50);
  position: relative;

  @include mq(md) {
    margin-bottom: pxrem(85);
    margin-top: pxrem(85);
  }

  .paragraph-bg {
    bottom: 0;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;

    &::after {
      background-color: rgba($color-blue, 0.6);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .paragraph-wrapper {
    position: relative;
    z-index: 1;

    @include mq(lg) {
      display: flex;
      width: 100%;
    }
  }

  .paragraph-elem {
    padding: pxrem(20);

    @include mq(lg) {
      flex: 1;
      flex-shrink: 0;
      min-height: 50vh;
      padding: pxrem(30) pxrem(20);
    }

    @include mq(xl) {
      min-height: 60vh;
      padding: pxrem(30) pxrem(35);
    }

    @include mq(xxl) {
      padding: pxrem(40) pxrem(60);
    }

    &:not(&.paragraph-elem--title) {
      border-bottom: 1px solid $color-white;

      @include mq(lg) {
        border-bottom: 0;
        border-left: 1px solid $color-white;
        border-right: 1px solid $color-white;
      }
    }

    &.paragraph-elem--title {
      border-bottom: 1px solid $color-white;

      @include mq(lg) {
        border-bottom: none;
      }
    }

    &.paragraph-elem--link {
      @include display-3($color-white);
      display: flex;
      position: relative;
      text-decoration: none;
      transition: background-color $transition-default,
        opacity $transition-default;

      &:hover {
        background-color: rgba($color-blue, 0.3);
        opacity: 1;

        .paragraph-area-image {
          opacity: 1;
        }
      }

      @include mq(lg) {
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0.3;
      }

      .btn {
        margin-right: pxrem(5);

        @include mq(lg) {
          display: none;
        }
      }
    }

    // &::before {
    //   background-color: $color-blue;
    //   bottom: 0;
    //   content: '';
    //   display: block;
    //   left: 0;
    //   opacity: 0.45;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   // z-index: 1;
    // }
  }

  .paragraph-area-image {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all $transition-default;
    z-index: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .area-button,
  .area-label {
    position: relative;
    z-index: 2;
  }

  .paragraph-title {
    @include display-2($color-white);
    font-weight: 400;
  }

  .paragraph-text {
    @include paragraph;
    font-weight: 400;
    margin-bottom: pxrem(50);

    @include mq(md) {
      margin-bottom: pxrem(70);
    }

    p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
