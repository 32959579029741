<script setup>
import {register} from 'swiper/element/bundle'
import {reactive} from 'vue'
import {useLocalePath} from 'vue-i18n-routing'

register()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const localePath = useLocalePath()

const content = reactive(props.content)

const swiperContainer = ref()

const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 480px
    480: {
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    // when window width is >= 768px
    768: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
  },
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section class="latest-news">
    <div class="latest-news-header">
      <h2 class="latest-news-title">
        {{ content.title }}
      </h2>
      <div class="latest-news-cta latest-news-cta--desktop">
        <SharedBasicCta :label="$t('archive')" url="/news/" color="dark" />
      </div>
    </div>
    <div class="swiper swiper--news">
      <swiper-container
        init="false"
        ref="swiperContainer"
        events-prefix="swiper">
        <div slot="container-end">
          <div v-if="content.list.length > 1" class="swiper-navigation">
            <div class="swiper-button swiper-button-prev sensible">
              <div class="btn btn--round btn--round-dark">
                <svg class="icon icon--btn-round icon--rotate-180">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </div>
            </div>
            <div class="swiper-button swiper-button-next sensible">
              <div class="btn btn--round btn--round-dark">
                <svg class="icon icon--btn-round">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination--dark"></div>
        </div>
        <swiper-slide class="swiper-slide" v-for="news in content.list">
          <SharedCardsNews
            :title="news.title"
            :date="news.date"
            :slug="news.slug"
            :images="news.assets" />
        </swiper-slide>
      </swiper-container>
    </div>
    <div class="latest-news-cta latest-news-cta--mobile">
      <SharedBasicCta :label="$t('archive')" url="/news/" color="dark" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.latest-news {
  padding-bottom: pxrem(60);
  padding-top: pxrem(60);

  @include mq(lg) {
    padding-bottom: pxrem(100);
    padding-top: pxrem(100);
  }

  .latest-news-header {
    @include make-col-ready;
    margin-bottom: pxrem(30);

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: pxrem(56);
    }
  }

  .latest-news-title {
    @include display-2;
    font-weight: 400;
  }

  .latest-news-cta {
    &.latest-news-cta--desktop {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &.latest-news-cta--mobile {
      @include make-col-ready();
      margin-top: pxrem(35);
      text-align: center;

      @include mq(md) {
        display: none;
      }
    }
  }
}

.swiper--news {
  .swiper-slide {
    border-bottom: 1px solid $color-gray-blue;
    border-left: 1px solid $color-gray-blue;
    border-top: 1px solid $color-gray-blue;
    height: auto;
    padding-bottom: 1px;
  }

  div[slot='container-end'] {
    @include make-col-ready();
    display: flex;
    justify-content: space-between;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .swiper-navigation {
    margin-top: pxrem(15);

    .swiper-button-prev {
      margin-right: pxrem(8);
    }
  }

  .swiper-pagination {
    display: none;

    @include mq(sm) {
      display: block;
      margin-top: pxrem(34);
      width: auto;
    }
  }
}
</style>
