<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const {locale} = useI18n()
const localePath = useLocalePath()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  images: {
    type: Array,
  },
  slug: {
    type: String,
    required: true,
  },
  layout: {
    type: Number,
    default: 1,
    validator(value) {
      return value >= 1 && value <= 3
    },
  },
})
</script>

<template>
  <div class="card-news animate-in-fade" :class="'layout--' + props.layout">
    <NuxtLink
      :to="localePath(`/news/${props.slug}`)"
      class="card-link sensible-more">
      <div class="card-top">
        <div class="card-date">{{ useDateFormatter(props.date, locale) }}</div>
        <h3 class="card-title">{{ props.title }}</h3>
      </div>
      <div class="card-bottom">
        <SharedBasicImage
          v-if="props.images"
          :assets="props.images"
          :alternative="props.title"
          :animate="false"
          class="card-image" />
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss">
.card-news {
  background-color: $color-white;
  height: 100%;
  transition: background-color $transition-default;

  &:hover {
    // background-color: $color-gray-light;

    .card-image {
      img {
        transform: scale(1.025);
      }
    }
  }

  .card-link {
    cursor: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: pxrem(20) pxrem(20) pxrem(30);
    text-decoration: none;

    @include mq(lg) {
      padding: pxrem(20) pxrem(40) pxrem(40);
    }
  }

  .card-top {
    margin-bottom: pxrem(50);
    width: 75%;
  }

  .card-date {
    @include paragraph;
    margin-bottom: pxrem(14);
  }

  .card-title {
    @include display-3;
    font-weight: 400;
  }

  .card-bottom {
    margin-top: auto;
    overflow: hidden;
    position: relative;
  }

  .card-image {
    overflow: hidden;

    img {
      aspect-ratio: 1.3;
      transition: transform $transition-default;
      width: 100%;
      will-change: transition;
    }
  }

  // LAYOUT 2
  &.layout--2 {
    .card-link {
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding: pxrem(20) pxrem(12);
    }

    .card-top {
      margin-bottom: 0;
    }

    .card-bottom {
      margin-bottom: pxrem(30);
      margin-top: 0;
    }
  }

  // LAYOUT 3
  &.layout--3 {
    .card-link {
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding: 0;
    }

    .card-top {
      margin-bottom: 0;
    }

    .card-bottom {
      margin-bottom: pxrem(30);
      margin-top: 0;
    }
  }
}
</style>
