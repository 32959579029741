<script setup>
import {onBeforeMount, ref} from 'vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const {$listen} = useNuxtApp()

const visibleResources = ref([])
const selectedCategory = ref('Press Release')
const selectedSector = ref('')
const selectedYear = ref('')

visibleResources.value = props.content.public

const filterCategories = []
const filterSectors = []
const filterYears = []

function isInArray(array, item) {
  let found = false
  array.forEach((element) => {
    if (element.value === item) found = true
  })
  return found
}

visibleResources.value.forEach((item) => {
  if (item.context && !isInArray(filterCategories, item.context)) {
    filterCategories[filterCategories.length] = {
      label: item.context,
      value: item.context,
    }
  }
  if (item.sector && !isInArray(filterSectors, item.sector.title)) {
    filterSectors[filterSectors.length] = {
      label: item.sector.title,
      value: item.sector.title,
    }
  }
  if (item.year && !isInArray(filterYears, item.year)) {
    filterYears[item.year] = {
      label: item.year,
      value: item.year,
    }
  }
})

function arraySort(array) {
  array.sort((a, b) => {
    if (a.value < b.value) {
      return -1
    }
    if (a.value > b.value) {
      return 1
    }
    return 0
  })
}

function resourcesArraySort(array) {
  array.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateB - dateA
  })
}

onBeforeMount(() => {
  // resourcesArraySort(visibleResources.value)
  // arraySort(filterCategories)
  // arraySort(filterSectors)
})

onMounted(() => {
  $listen('change:selectValue', (element) => {
    if (element.className == 'filter-category') {
      selectedCategory.value = element.optionValue
      currentPage.value = 1
      raiseCustomEvent()
    }
    if (element.className == 'filter-sector') {
      selectedSector.value = element.optionValue
      currentPage.value = 1
      raiseCustomEvent()
    }
    if (element.className == 'filter-year') {
      selectedYear.value = element.optionValue
      currentPage.value = 1
      raiseCustomEvent()
    }
  })

  resourcesArraySort(visibleResources.value)
  arraySort(filterCategories)
  arraySort(filterSectors)
})

function filterByCategory(resources) {
  if (selectedCategory.value === '') return resources
  return resources.filter(
    (resource) => resource.context === selectedCategory.value
  )
}

function filterBySector(resources) {
  if (selectedSector.value === '') return resources
  return resources.filter(
    (resource) => resource.sector?.title === selectedSector.value
  )
}

function filterByYear(resources) {
  if (selectedYear.value === '') return resources
  return resources.filter((resource) => resource.year === selectedYear.value)
}

// Computed property to filter resources based on selected category
const filteredResources = computed(() => {
  return selectedCategory.value === '' &&
    selectedSector.value === '' &&
    selectedYear.value === ''
    ? visibleResources.value
    : filterByCategory(filterBySector(filterByYear(visibleResources.value)))
})

const resourcesPerPage = 9
const currentPage = ref(1)

// Computed property to calculate the total number of pages
const totalPages = computed(() =>
  Math.ceil(filteredResources.value.length / resourcesPerPage)
)

// Computed property to determine the index of the first and last resource on the current page
const startIndex = computed(() => (currentPage.value - 1) * resourcesPerPage)
const endIndex = computed(() =>
  Math.min(
    startIndex.value + resourcesPerPage - 1,
    filteredResources.value.length - 1
  )
)

// Function to navigate to the previous page
const goToPrevPage = async () => {
  if (currentPage.value > 1) {
    currentPage.value--
    
    await nextTick()
    raiseCustomEvent()
  }
}

// Function to navigate to the next page
const goToNextPage = async () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
    
    await nextTick()
    raiseCustomEvent()
  }
}

// Computed property to generate an array of page numbers
const pageNumbers = computed(() => {
  const pages = []
  for (let i = 1; i <= totalPages.value; i++) {
    pages.push(i)
  }
  return pages
})

// Function to navigate to a specific page
const goToPage = async (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page

    await nextTick()
    raiseCustomEvent()
  }
}

const raiseCustomEvent = () => {
  // Dispatch the custom event on the window object
  // setTimeout(() => {
  const updateAnimationsEvent = new CustomEvent('updateAnimationsEvent', {})
  window.dispatchEvent(updateAnimationsEvent)
  // }, 500)
}

// Function to manage filters selects reset
const categoryRef = ref(null)
const sectorRef = ref(null)
const yearRef = ref(null)

const resetFilterSelects = async () => {
  categoryRef.value.resetSelect()
  sectorRef.value.resetSelect()
  yearRef.value.resetSelect()

  await nextTick()
  raiseCustomEvent()
}
</script>

<template>
  <section
    class="listing listing--media-hub listing--media-hub-public"
    id="content">
    <div class="listing-filters-wrapper">
      <div class="listing-filters-internal-wrapper">
        <div class="form-field">
          <label class="form-label" for="reason">{{ $t('media_hub.category_filter') }}</label>
          <SharedBasicCustomSelect
            ref="categoryRef"
            :options="filterCategories"
            :allLabel="$t('media_hub.all_categories')"
            :addClass="'filter-category'"
            name="category"
            preselected="Press Release" />
        </div>
        <div class="form-field">
          <label class="form-label" for="reason">{{ $t('media_hub.sector_filter') }}</label>
          <SharedBasicCustomSelect
            ref="sectorRef"
            :options="filterSectors"
            :allLabel="$t('media_hub.all_sectors')"
            :addClass="'filter-sector'"
            name="sector" />
        </div>
        <div class="form-field">
          <label class="form-label" for="reason">{{ $t('media_hub.year_filter') }}</label>
          <SharedBasicCustomSelect
            ref="yearRef"
            :options="filterYears.reverse()"
            allLabel="-"
            :addClass="'filter-year'"
            name="year" />
        </div>
      </div>
    </div>
    <div class="listing-header">
      <!-- <h2 class="listing-title">{{ $t('latest_upload') }}</h2> -->
    </div>
    <div class="listing-wrapper sensible-more">
      <div
        v-for="(resource, index) in filteredResources.slice(
          startIndex,
          endIndex + 1
        )"
        :key="index"
        class="listing-item">
        <SharedCardsResource
          :title="resource.title"
          :date="resource.date"
          :context="resource.context"
          :type="resource.type"
          :size="resource.size"
          :urlDownload="resource.download"
          :images="resource.preview_assets" />
      </div>
    </div>
    <div class="no-results-title" v-if="filteredResources == ''">
      {{ $t('no_results') }}
    </div>
    <div class="listing-footer">
      <div class="pagination" v-show="totalPages > 1">
        <button
          class="pagination-item pagination-item--prev sensible"
          @click="goToPrevPage"
          :disabled="currentPage === 1">
          <div class="btn btn--round btn--round-dark">
            <svg class="icon icon--btn-round icon--rotate-180">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </button>
        <div class="page-numbers">
          <button
            class="pagination-item pagination-item--page sensible"
            v-for="page in pageNumbers"
            :key="page"
            @click="goToPage(page)"
            :class="{current: page === currentPage}">
            {{ page }}
          </button>
        </div>
        <button
          class="pagination-item pagination-item--next sensible"
          @click="goToNextPage"
          :disabled="currentPage === totalPages">
          <div class="btn btn--round btn--round-dark">
            <svg class="icon icon--btn-round">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.listing--media-hub-public {
  margin-bottom: pxrem(110);

  .listing-filters-wrapper {
  }

  .listing-filters-internal-wrapper {
    @include make-col-ready();

    padding-top: pxrem(45);

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      column-gap: pxrem(16);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .listing-header {
    @include make-col-ready();
    margin-top: pxrem(30);

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      margin-top: pxrem(60);
    }
  }

  .listing-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(40);

    @include mq(md) {
      margin-bottom: pxrem(60);
    }
  }

  .listing-wrapper {
    @include make-col-ready();
    margin-bottom: pxrem(60);

    @include mq(xs) {
      column-gap: pxrem(10);
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: pxrem(30);
    }

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      column-gap: pxrem(15);
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: pxrem(50);
    }
  }

  .no-results-title {
    @include display-2;
    text-align: center;
  }

  .listing-footer {
    @include make-col-ready();
    margin-top: pxrem(100);
    text-align: center;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .form-field {
    margin-bottom: pxrem(20);

    @include mq(md) {
      margin-bottom: 0;
    }
  }

  .form-label {
    @include paragraph;
    display: block;
    margin-bottom: pxrem(5);
  }

  .pagination {
  }

  .page-numbers {
    display: inline-block;
    margin-left: pxrem(20);
    margin-right: pxrem(20);
  }

  .pagination-item {
    @include button-reset;
    display: inline-block;

    &.pagination-item--page {
      @include paragraph($color-gray-blue);
      padding: pxrem(5) pxrem(10);
      transition: all $transition-default;

      &.current,
      &:hover {
        color: $color-blue;
      }
    }

    &.pagination-item--prev,
    &.pagination-item--next {
      transition: all $transition-default;

      &[disabled] {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}
</style>
