<script setup>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
  },
})

const nuxtApp = useNuxtApp()

const extractVideoCode = (input) => {
  const pattern =
    /(?:\/|v=|be\/|embed\/|youtu\.be\/|watch\?v=|watch\?.+&v=|^)([a-zA-Z0-9_-]{11})/
  const match = input.match(pattern)

  if (match && match[1]) {
    return match[1]
  }

  return null
}

const {$listen} = useNuxtApp()
const videoId = ref(extractVideoCode(props.content.url))
const tabPreview = ref()
const playerContainer = ref()

onMounted(() => {
  // listening for tab change signal
  $listen('tab:changed', (video_id) => {
    // initialize video embed
    // console.log('Event listener for tab change', video_id)
    let selectedElem = document.querySelector('#'+video_id+' .tab-preview')
    selectedElem.classList.remove('tab-preview--hidden')
    if(playerContainer.value?.getPlayerInstance()){
      playerContainer.value?.stopVideo()
    }
  })
})



const onPlayClick = (event) => {
  tabPreview.value.classList.add('tab-preview--hidden')
  // console.log('PlayerContainer:', playerContainer.value)
  if(!playerContainer.value?.getPlayerInstance()) playerContainer.value?.addIframe()
  // Waiting for iframe to be generated
  setTimeout(() => {
    playerContainer.value?.playVideo()
  }, 100)
}
</script>

<template>
  <div :id="'vid-'+videoId" class="tab-video tab-wrapper">
    <div class="tab-video-wrapper animate-in-bottom animate-out-right">
      <div
        class="tab-preview sensible"
        ref="tabPreview"
        @click="onPlayClick">
        <img class="preview-image" :src="(content.medias && content.medias[0].src)? content.medias[0].src : '/placeholders/placeholder.jpg'" />
        <div class="preview-cta">
          <div class="btn btn--round btn--round-light">
            <svg class="icon icon--btn-round">
              <use xlink:href="#icon-play"></use>
            </svg>
          </div>
          <span>Play video</span>
        </div>
      </div>
      <LiteYouTubeEmbed :id="videoId" ref="playerContainer" :announce="title" :title="title" />
    </div>
  </div>
</template>

<style lang="scss">
.tab-wrapper {
  margin-bottom: pxrem(30);
  user-select: none;

  @include mq(md) {
    margin-bottom: pxrem(50);
  }
}

.tab-video-wrapper {
  aspect-ratio: 16 / 9;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  .tab-preview {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: none;
    z-index: 1;

    &.tab-preview--hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity $transition-default 300ms;
    }

    &::before {
      background: linear-gradient(
        180deg,
        rgba(8, 20, 39, 0.04) 0%,
        rgba(8, 20, 39, 1) 100%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .preview-image {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    width: 100%;
  }

  .preview-cta {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    .btn {
      margin-bottom: pxrem(20);
    }

    span {
      @include label($color-white);
    }
  }
}
</style>
