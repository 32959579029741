<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const slides = reactive(props.slides)

const swiperContainer = ref()
const carouselLabels = ref([])
// const slideIndices = reactive([])

// slides subgroups
const slideGroupCounter = ref({})

// filter labels in order to avoid duplicates and save
const uniqueLabels = computed(() => {
  const uniqueLabelsArray = []
  // slideGroupCounter.value = {} // Reset slide group counter

  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i]
    if (!uniqueLabelsArray.some((labelObj) => labelObj.label === slide.label)) {
      uniqueLabelsArray.push({label: slide.label, index: i})
    }

    // slideGroupCounter.value[slide.label] =
    //   (slideGroupCounter.value[slide.label] || 0) + 1
  }

  return uniqueLabelsArray
})

const updateSlideUniqueLabelsLogic = () => {
  slideGroupCounter.value = {}
  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i]

    slideGroupCounter.value[slide.label] =
      (slideGroupCounter.value[slide.label] || 0) + 1
  }
}

// to be revised

watch(slides, updateSlideUniqueLabelsLogic, {deep: true})

// current slide inside a subgroup
const slideIndices = computed(() => {
  const slideIndicesArray = []

  for (let i = 0; i < slides.length; i++) {
    slideIndicesArray[i] =
      i - uniqueLabels.value[getUniqueLabelIndex(slides[i].label)].index + 1
  }

  return slideIndicesArray
})

// get corresponding label index when scrolling slides
const getUniqueLabelIndex = (label) => {
  const index = uniqueLabels.value.findIndex(
    (labelObj) => labelObj.label === label
  )
  return index !== -1 ? index : null
}

const initCarouselLabels = (event) => {
  setTimeout(() => {
    const activeSlide = event.target.querySelector('.swiper-slide-active')

    let carouselLabelTarget = null

    if (activeSlide && activeSlide.dataset) {
      carouselLabelTarget = event.target.querySelector(
        '.' + activeSlide.dataset.target
      )
    }

    carouselLabels.value.forEach((carouselLabel) => {
      carouselLabel.classList.remove('active')
    })

    if (carouselLabelTarget && carouselLabelTarget.classList) {
      carouselLabelTarget.classList.add('active')
    }
  }, 1200)
}

const updateCarouselLabels = (event) => {
  const activeSlide = event.target.querySelector('.swiper-slide-active')

  if (activeSlide) {
    const carouselLabelTarget = event.target.querySelector(
      '.' + activeSlide.dataset.target
    )

    carouselLabels.value.forEach((carouselLabel) => {
      carouselLabel.classList.remove('active')
    })

    if (carouselLabelTarget && carouselLabelTarget.classList) {
      carouselLabelTarget.classList.add('active')
    }
  }
}

const updateCurrentSlide = (event) => {
  swiperContainer.value.swiper.slideTo(event.target.dataset.slide, 300, true)
}

const swiperParams = {
  direction: 'horizontal',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
  breakpoints: {
    768: {
      direction: 'vertical',
    },
  },
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   initializeSlider()
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <div
    class="swiper swiper--text-image-multiple animate-in-fade animate-out-fade">
    <swiper-container
      @vue:updated="initialized"
      init="false"
      ref="swiperContainer"
      events-prefix="swiper"
      @swiperafterinit="initCarouselLabels"
      @swiperslidechangetransitionend="updateCarouselLabels">
      <div slot="container-end">
        <ul class="swiper-labels animate-in-bottom animate-out-left">
          <li
            v-for="(slide, index) in uniqueLabels"
            class="swiper-label sensible"
            :class="['swiper-label--' + index, index == 0 ? 'active' : '']"
            :data-slide="slide.index"
            ref="carouselLabels"
            @click="updateCurrentSlide">
            {{ slide.label }}
          </li>
        </ul>
        <div
          :v-if="slides"
          class="swiper-navigation animate-in-bottom animate-out-left">
          <div class="swiper-button swiper-button-prev sensible">
            <div class="btn btn--round btn--round-dark">
              <svg
                class="icon icon--btn-round icon--btn-round--desktop icon--rotate-270">
                <use xlink:href="#icon-arrow"></use>
              </svg>
              <svg
                class="icon icon--btn-round icon--btn-round--mobile icon--rotate-180">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
          <div class="swiper-button swiper-button-next sensible">
            <div class="btn btn--round btn--round-dark">
              <svg
                class="icon icon--btn-round icon--btn-round--desktop icon--rotate-90">
                <use xlink:href="#icon-arrow"></use>
              </svg>
              <svg class="icon icon--btn-round icon--btn-round--mobile">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <swiper-slide
        class="swiper-slide"
        v-for="(slide, index) in slides"
        :data-target="'swiper-label--' + getUniqueLabelIndex(slide.label)">
        <div class="slide-text-image">
          <div class="slide-left">
            <SharedBasicImage
              :assets="slide.assets"
              :alternative="slide.title"
              :animate="false"
              class="swiper-slide-image" />
            <div
              class="slide-group-label"
              :class="{odd: index % 2 !== 0, even: index % 2 === 0}"
              v-if="slideGroupCounter[slide.label] > 1">
              {{ slideIndices[index] }} / {{ slideGroupCounter[slide.label] }}
            </div>
          </div>
          <div class="slide-right">
            <div class="slide-content">
              <h3 class="slide-label-mobile">
                {{ slide.label }}
              </h3>
              <h3 class="slide-title">
                {{ slide.title }}
              </h3>
              <div class="slide-text" v-html="slide.text"></div>
              <div class="slide-cta" v-if="slide.cta">
                <SharedBasicCta
                  :label="slide.cta.label"
                  :url="slide.cta.url"
                  color="dark" />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style lang="scss">
.swiper--text-image-multiple {
  swiper-container {
    @include mq(md) {
      height: 60vh !important;
    }

    @include mq(xl) {
      height: 80vh !important;
    }
  }

  .swiper-slide {
    @include mq(md) {
      align-items: center;
      display: flex;
      height: 60vh !important;
    }

    @include mq(xl) {
      height: 80vh !important;
    }
  }

  .swiper-slide-image {
    img {
      aspect-ratio: 1;
      object-fit: contain !important;
      width: 100%;

      @include mq(md) {
        aspect-ratio: 1.45;
      }
    }
  }

  div[slot='container-end'] {
    @include mq(md) {
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }

  .swiper-labels {
    display: none;

    @include mq(md) {
      background-image: linear-gradient(
        to right,
        $color-gray-light pxrem(20),
        transparent,
        transparent
      );
      display: block;
      list-style: none;
      padding-left: 0;
      z-index: 1;

      .swiper-label {
        @include label;
        align-items: center;
        display: flex;
        margin-bottom: pxrem(18);
        opacity: 0.4;
        transition: opacity $transition-default;

        &::before {
          border: 1px solid $color-blue;
          border-radius: 50%;
          content: '';
          display: block;
          height: 0.365em;
          margin-right: pxrem(10);
          transition: background-color $transition-default;
          width: 0.375em;
        }

        &.active {
          opacity: 1;

          &::before {
            background-color: $color-blue;
          }
        }
      }
    }
  }

  .swiper-navigation {
    margin-top: pxrem(15);
    text-align: center;
    z-index: 0;

    @include mq(md) {
      bottom: 1px;
      position: absolute;
      width: pxrem(45);

      &::before {
        background-color: $color-gray;
        content: '';
        display: block;
        height: 12vh;
        margin: pxrem(25) auto;
        width: 1px;

        @include mq(xl) {
          height: 20vh;
        }
      }
    }

    .swiper-button {
      display: inline-block;
      margin-left: pxrem(5);
      margin-right: pxrem(5);

      @include mq(md) {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }

      &.swiper-button-prev {
        margin-bottom: pxrem(8);
      }

      .icon--btn-round--desktop {
        display: none;

        @include mq(md) {
          display: block;
        }
      }

      .icon--btn-round--mobile {
        @include mq(md) {
          display: none;
        }
      }
    }
  }

  .slide-text-image {
    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
      // align-items: center;
      display: flex;
    }
  }

  .slide-left {
    position: relative;

    @include mq(md) {
      @include make-col(10);
    }

    @include mq(xxl) {
      @include make-col(12);
    }

    .slide-group-label {
      color: $color-gray;
      font-size: $font-xxxlarge;
      position: absolute;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.75);

      @include mq(md) {
        font-size: $font-huge;
        text-shadow: none;
      }

      &.even {
        bottom: pxrem(20);
        left: pxrem(20);

        @include mq(md) {
          bottom: -50px;
          left: 0;
        }
      }

      &.odd {
        right: pxrem(20);
        top: pxrem(20);

        @include mq(md) {
          right: 0;
          top: -30px;
        }
      }
    }
  }

  .slide-right {
    @include mq(md) {
      @include make-col(9);
      @include make-col-offset(1);
    }

    @include mq(xxl) {
      @include make-col(7);
    }
  }

  .slide-content {
    text-align: center;

    @include mq(md) {
      text-align: left;
    }
  }

  .slide-label-mobile {
    @include label;
    font-weight: 400;
    margin-bottom: pxrem(10);
  }

  .slide-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(20);
  }

  .slide-text {
    @include paragraph-small;
    margin-bottom: pxrem(10);

    @include mq(xxl) {
      @include paragraph;
      margin-bottom: pxrem(10);
    }
  }
}
</style>
