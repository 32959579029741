<script setup>
import {ref} from 'vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const visibleNews = ref([])
const itemsPerPage = 6
const currentPage = ref(1)

const totalNews = props.content.list.length
const totalPages = Math.ceil(totalNews / itemsPerPage)

const showLoadMoreButton = ref(currentPage.value <= totalPages)

const raiseCustomEvent = () => {
  // Dispatch the custom event on the window object
  const updateAnimationsEvent = new CustomEvent('updateAnimationsEvent', {})
  window.dispatchEvent(updateAnimationsEvent)
}

// Qualcosa non va, funziona solo se si refresha la pagina ma non sul cambio
const loadMoreNews = async () => {
  const startIndex = (currentPage.value - 1) * itemsPerPage
  const endIndex = currentPage.value * itemsPerPage
  visibleNews.value = [
    ...visibleNews.value,
    ...props.content.list.slice(startIndex, endIndex),
  ]

  currentPage.value++
  showLoadMoreButton.value = currentPage.value <= totalPages

  if (currentPage.value - 1 > 1) {
    // Attendiamo che il DOM sia aggiornato
    await nextTick()
    // Eseguiamo la funzione raiseCustomEvent
    raiseCustomEvent()
  }
}

onMounted(() => {
  loadMoreNews()
})
</script>

<template>
  <section class="listing listing--news">
    <h2 class="listing-title">
      {{ $t('archive') }}
    </h2>
    <div class="listing-wrapper sensible-more">
      <div
        v-for="(news, index) in visibleNews"
        :key="index"
        class="listing-item">
        <SharedCardsNews
          :title="news.title"
          :date="news.date"
          :slug="news.slug"
          :images="news.assets" />
      </div>
    </div>
    <div class="listing-actions">
      <button
        v-if="showLoadMoreButton"
        @click="loadMoreNews"
        class="load-more-button btn btn--primary-dark sensible">
        {{ $t('load_more') }}
      </button>
    </div>
    <!-- Workaround for full static generation -->
    <div class="h-visually-hidden">
      <div v-for="(news, index) in props.content.list">
        <NuxtLink :to="localePath(`/news/${news.slug}`)" class="">{{
          news.title
        }}</NuxtLink>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.listing--news {
  margin-bottom: pxrem(110);
  margin-top: pxrem(40);

  @include mq(md) {
    margin-top: pxrem(60);
  }

  .listing-title {
    @include make-col-ready();
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(40);

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      margin-bottom: pxrem(60);
    }
  }

  .listing-wrapper {
    border-bottom: 1px solid $color-gray-blue;
    margin-bottom: pxrem(60);

    @include mq(xs) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include mq(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .listing-item {
    border-left: 1px solid $color-gray-blue;
    border-top: 1px solid $color-gray-blue;
  }

  .listing-actions {
    text-align: center;
  }
}
</style>
