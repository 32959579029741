<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const swiperContainer = ref()

const swiperParams = {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   if (swiperContainer.value) {
//     Object.assign(swiperContainer.value, swiperParams)
//     swiperContainer.value.initialize()
//   }
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section class="paragraph paragraph--title-text-cta-number">
    <div class="paragraph-wrapper paragraph-wrapper--desktop">
      <div class="paragraph-element" v-for="element in content.list">
        <div
          class="paragraph-element-left animate-in-stagger-bottom animate-out-left">
          <h2 class="paragraph-element-title animate-stagger-item">
            {{ element.title }}
          </h2>
          <div
            class="paragraph-element-text animate-stagger-item"
            v-html="element.text"></div>
          <div
            class="paragraph-element-cta animate-stagger-item"
            v-if="element.cta">
            <SharedBasicCta
              :label="element.cta.label"
              :url="element.cta.url"
              color="dark" />
          </div>
        </div>
        <div class="paragraph-element-right">
          <div class="paragraph-element-blob">
            <SharedBlob
              class="animate-in-fade animate-out-right"
              color="dark"
              size="small" />
            <div
              class="paragraph-element-number animate-in-fade animate-out-right">
              {{ element.number }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="paragraph-wrapper paragraph-wrapper--mobile animate-in-bottom animate-out-right">
      <div class="swiper swiper--numbers">
        <swiper-container
          init="false"
          ref="swiperContainer"
          events-prefix="swiper">
          <div slot="container-end">
            <div v-if="content.list.length > 1" class="swiper-navigation">
              <div class="swiper-button swiper-button-prev sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round icon--rotate-180">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
              <div class="swiper-button swiper-button-next sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <swiper-slide class="swiper-slide" v-for="element in content.list">
            <div class="paragraph-element">
              <div class="paragraph-element-left">
                <h2 class="paragraph-element-title">{{ element.title }}</h2>
                <div class="paragraph-element-text" v-html="element.text"></div>
                <div class="paragraph-element-cta" v-if="element.cta">
                  <SharedBasicCta
                    :label="element.cta.label"
                    :url="element.cta.url"
                    color="dark" />
                </div>
              </div>
              <div class="paragraph-element-right">
                <div class="paragraph-element-blob">
                  <SharedBlob color="dark" size="small" />
                  <div class="paragraph-element-number">
                    {{ element.number }}
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.paragraph--title-text-cta-number {
  margin-bottom: pxrem(50);
  margin-top: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(120);
    margin-top: pxrem(120);
  }

  .paragraph-wrapper {
    &.paragraph-wrapper--desktop {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &.paragraph-wrapper--mobile {
      @include mq(md) {
        display: none;
      }
    }
  }

  .paragraph-element {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: pxrem(30);

    @include mq(md) {
      align-items: center;
      display: flex;
      flex-direction: row;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }
  }

  .paragraph-element-left {
    @include mq(md) {
      margin-left: 7.5%;
      margin-right: 7.5%;
      width: 30%;
    }
  }

  .paragraph-element-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(20);
  }

  .paragraph-element-text {
    @include paragraph;
  }

  .paragraph-element-cta {
  }

  .paragraph-element-right {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    @include mq(md) {
      width: 55%;
    }
  }

  .paragraph-element-blob {
    width: 100%;

    .blob {
      width: 100%;
    }
  }

  .paragraph-element-number {
    font-size: pxrem(80);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include mq(lg) {
      font-size: pxrem(100);
    }

    @include mq(xl) {
      font-size: pxrem(135);
    }
  }

  .swiper--numbers {
    .swiper-navigation {
      margin-top: pxrem(15);

      .swiper-button-prev {
        margin-right: pxrem(8);
      }
    }
  }
}
</style>
