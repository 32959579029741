<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <section
    class="paragraph paragraph--ctas-big"
    :class="'layout--' + content.layout">
    <!-- {{ content }} -->
    <div class="paragraph-wrapper">
      <ul class="paragraph-list animate-out-left">
        <li
          class="paragraph-item animate-in-bottom"
          v-for="cta in content.ctas">
          <div class="paragraph-item-wrapper">
            <NuxtLink class="paragraph-link sensible" :to="localePath(cta.url)">
              <span>
                {{ cta.label }}
              </span>
              <div class="paragraph-cta-btn btn btn--round btn--round-dark">
                <svg class="icon icon--btn-round">
                  <use xlink:href="#icon-arrow"></use>
                </svg></div
            ></NuxtLink>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss">
.paragraph--ctas-big {
  margin-bottom: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(85);
  }

  .paragraph-list {
    @include list-reset;
  }

  .paragraph-item {
    border-bottom: 1px solid $color-gray-blue;
    transition: background-color $transition-default;

    &:hover {
      background-color: $color-gray-light;
    }
  }

  .paragraph-item-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
    }
  }

  .paragraph-link {
    @include display-2;
    align-items: center;
    display: flex;
    font-family: $font-secondary;
    font-weight: 400;
    justify-content: space-between;
    padding-bottom: pxrem(30);
    padding-top: pxrem(30);
    text-decoration: none;

    @include mq(md) {
      @include display-1;
      padding-bottom: pxrem(50);
      padding-top: pxrem(50);
    }

    &:hover {
      .btn.btn--round.btn--round-dark {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-white;

        svg {
          @include icon-color($color: $color-white);
        }
      }
    }
  }

  .paragraph-cta-btn {
    margin-left: pxrem(10);
  }

  &.layout--2 {
    .paragraph-link {
      @include paragraph;
      align-items: center;
      display: flex;
      font-weight: 400;
      justify-content: space-between;
      padding-bottom: pxrem(30);
      padding-top: pxrem(30);
      text-decoration: none;

      @include mq(md) {
        @include paragraph-big;
        padding-bottom: pxrem(50);
        padding-top: pxrem(50);
      }
    }
  }
}
</style>
