<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const swiperContainer = ref()

const swiperParams = {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 'auto',
  spaceBetween: 40,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   if (swiperContainer.value) {
//     Object.assign(swiperContainer.value, swiperParams)
//     swiperContainer.value.initialize()
//   }
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section class="paragraph paragraph--list-box">
    <div class="paragraph-wrapper">
      <h2 class="paragraph-title">{{ content.title }}</h2>
      <div class="paragraph-content">
        <div class="paragraph-box" v-for="box in content.list">
          <h3 class="paragraph-box-title">
            {{ box.title }}
          </h3>
          <div class="paragraph-box-description">
            {{ box.text }}
          </div>
        </div>
      </div>
      <div class="swiper swiper--boxes">
        <swiper-container
          init="false"
          ref="swiperContainer"
          events-prefix="swiper">
          <div slot="container-end">
            <div v-if="content.list.length > 1" class="swiper-navigation">
              <div class="swiper-button swiper-button-prev sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round icon--rotate-180">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
              <div class="swiper-button swiper-button-next sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <swiper-slide class="swiper-slide" v-for="box in content.list">
            <div class="paragraph-box">
              <h3 class="paragraph-box-title">
                {{ box.title }}
              </h3>
              <div class="paragraph-box-description">
                {{ box.text }}
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.paragraph--list-box {
  margin-bottom: pxrem(50);
  margin-top: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(120);
    margin-top: pxrem(120);
  }

  .paragraph-title {
    @include display-1;
    font-weight: 400;
    margin-bottom: pxrem(50);
    text-align: center;

    @include mq(md) {
      margin-bottom: pxrem(80);
    }
  }

  .paragraph-content {
    display: none;

    @include mq(md) {
      column-gap: col-width(1);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: col-width(1);
    }
  }

  .paragraph-box {
    border-top: 1px solid $color-gray;
    padding-top: pxrem(30);
  }

  .paragraph-box-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(30);
    width: 80%;
  }

  .paragraph-box-description {
    @include paragraph;

    @include mq(md) {
      width: 80%;
    }
  }

  .swiper--boxes {
    @include mq(md) {
      display: none;
    }

    .swiper-slide {
      align-items: center;
      display: flex;
      height: auto;
      max-width: 100%;
      width: auto;
    }

    .swiper-navigation {
      margin-top: pxrem(50);
      .swiper-button-prev {
        margin-right: pxrem(8);
      }
    }
  }
}
</style>
