<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const content = reactive(props.content)

const swiperContainer = ref()

const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 480px
    480: {
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    // when window width is >= 768px
    768: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
  },
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section class="news-suggested">
    <div class="news-suggested-wrapper">
      <h2 class="news-suggested-title animate-in-bottom animate-out-left">
        {{ content.title }}
      </h2>
      <div class="news-suggested-list">
        <div
          v-for="(news, index) in content.list"
          :key="index"
          class="news-suggested-item sensible animate-in-bottom animate-out-left">
          <SharedCardsNews
            :title="news.title"
            :date="news.date"
            :slug="news.slug"
            :images="news.assets"
            :layout="2" />
        </div>
      </div>
      <div class="swiper swiper--news animate-in-bottom animate-out-left">
        <swiper-container
          init="false"
          ref="swiperContainer"
          events-prefix="swiper">
          <div slot="container-end">
            <div v-if="content.list.length > 1" class="swiper-navigation">
              <div class="swiper-button swiper-button-prev sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round icon--rotate-180">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
              <div class="swiper-button swiper-button-next sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination--dark"></div>
          </div>
          <swiper-slide class="swiper-slide" v-for="news in content.list">
            <SharedCardsNews
              :title="news.title"
              :date="news.date"
              :slug="news.slug"
              :images="news.assets" />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.news-suggested {
  border-top: 1px solid $color-gray;
  margin-bottom: pxrem(120);
  .news-suggested-wrapper {
    @include make-col-ready();

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .news-suggested-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(45);
    padding-top: pxrem(90);
    text-align: center;
  }

  .news-suggested-list {
    display: none;

    @include mq(md) {
      // column-gap: pxrem(15);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .news-suggested-item {
    margin-bottom: pxrem(20);
  }

  .swiper--news {
    @include mq(md) {
      display: none;
    }

    .swiper-slide {
      height: auto;
      padding-bottom: 1px;
    }

    div[slot='container-end'] {
      @include make-col-ready();
      display: flex;
      justify-content: space-between;

      @include mq(md) {
        @include make-col(22);
        @include make-col-offset(1);
      }
    }

    .swiper-navigation {
      margin-top: pxrem(15);

      .swiper-button-prev {
        margin-right: pxrem(8);
      }
    }

    .swiper-pagination {
      display: none;

      @include mq(sm) {
        display: block;
        margin-top: pxrem(34);
        width: auto;
      }
    }
  }
}
</style>
