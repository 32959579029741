export default function useDebounce(callback, delay) {
  let timerId

  function debouncedCallback(...args) {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      callback.apply(this, args)
    }, delay)
  }

  return debouncedCallback
}
