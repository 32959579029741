<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <section class="section section--offices-more">
    <div class="section-wrapper">
      <div
        class="more-item animate-in-stagger-bottom animate-out-left"
        v-for="item in content.list">
        <div class="more-title animate-stagger-item">{{ item.title }}</div>
        <div class="more-name animate-stagger-item">{{ item.name }}</div>
        <div class="more-role animate-stagger-item" v-html="item.role"></div>
        <div class="more-body animate-stagger-item" v-html="item.body"></div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section--offices-more {
  background-color: $color-gray-light;
  padding-bottom: pxrem(60);
  padding-top: pxrem(60);

  @include mq(md) {
    padding-bottom: pxrem(120);
    padding-top: pxrem(120);
  }
  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
      column-gap: col-width(3);
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .more-item {
    border-top: 1px solid $color-gray;
    margin-bottom: pxrem(30);
    padding-top: pxrem(30);

    @include mq(md) {
      margin-bottom: 0;
    }
  }

  .more-title {
    @include display-2;
    margin-bottom: pxrem(30);

    @include mq(md) {
      margin-bottom: pxrem(50);
    }
  }

  .more-name {
    @include display-3;
    font-weight: 700;
  }

  .more-role {
    @include display-3;
  }

  .more-body {
  }
}
</style>
