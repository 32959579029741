<script setup>
const props = defineProps({
  document: {
    type: Object,
    required: true,
  },
})

function generateFileNameFromURL(url) {
  if (url) {
    // const lastSlashIndex = url.lastIndexOf('/')
    // const fileNameWithQuery = url.substring(lastSlashIndex + 1)

    // const fileNameWithoutQuery = fileNameWithQuery.split('?')[0]

    // const timestamp = new Date().getTime()

    // const finalFileName = `${fileNameWithoutQuery}_${timestamp}`

    // return finalFileName
    if (!url) return props.title

    const lastSlashIndex = url.lastIndexOf('/')
    const fileNameWithQuery = url.substring(lastSlashIndex + 1)
    const fileNameWithoutQuery = fileNameWithQuery.split('?')[0]
    return fileNameWithoutQuery
  }

  return 'default'
}
</script>

<template>
  <a
    class="download-link sensible"
    :href="document.file"
    :download="generateFileNameFromURL(document.file)"
    target="_blank">
    <div class="download-link-left">
      <div class="btn btn--round btn--round-dark">
        <svg class="icon icon--btn-round">
          <use xlink:href="#icon-document"></use>
        </svg>
      </div>
    </div>
    <div class="download-link-right">
      <div class="document-title">{{ document.title }}</div>
      <div class="document-date" v-if="document.date">{{ document.date }}</div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
.download-link {
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    .btn {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-white;

      svg {
        @include icon-color($color: $color-white);
      }
    }
  }

  .download-link-left {
    flex-shrink: 0;

    .btn {
      margin-top: pxrem(5);
    }
  }

  .download-link-right {
    margin-left: pxrem(10);
    flex-grow: 1;
  }

  .document-title {
    @include paragraph;
    font-weight: 600;
  }

  .document-date {
    @include paragraph;
  }
}
</style>
