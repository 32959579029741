<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const peopleModal = ref()

function closeModal(event) {
  peopleModal.value.classList.remove('open')
  document.body.classList.remove('overflow-y-hidden')
}
</script>

<template>
  <div class="people-modal" ref="peopleModal">
    <div class="people-modal-close" @click="closeModal">
      <div class="people-modal-action sensible">
        <div class="people-modal-action-label">{{ $t('close') }}</div>
        <div class="people-modal-action-icon btn btn--round btn--round-light">
          <svg class="icon icon--btn-round">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="people-modal-wrapper">
      <div class="people-modal-internal-wrapper">
        <div class="people-modal-left">
          <SharedBasicImage
            :assets="content.assets"
            :alternative="content.name" />
        </div>
        <div class="people-modal-right">
          <div class="people-modal-right-scrollable">
            <div class="people-modal-title">{{ content.name }}</div>
            <div class="people-modal-role">{{ content.role }}</div>
            <div class="people-modal-bio" v-html="content.bio"></div>
            <div class="people-modal-cta">
              <NuxtLink
                v-if="content.linkedin"
                :to="content.linkedin"
                class="menu-link btn btn--round btn--round-light sensible">
                <svg class="icon icon--btn-round">
                  <use xlink:href="#icon-social-linkedin"></use>
                </svg>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.people-modal {
  background-color: $color-blue;
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  padding-bottom: pxrem(80);
  padding-top: pxrem(80);
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity $transition-default;
  z-index: 3;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  @include mq(lg) {
    padding-bottom: pxrem(100);
    padding-top: pxrem(100);
  }

  .people-modal-action {
    align-items: center;
    display: flex;
    position: absolute;
    right: col-width(1);
    top: pxrem(20);

    .people-modal-action-label {
      @include label($color-white);
      margin-right: pxrem(10);
    }
  }

  .people-modal-wrapper {
    @include make-col-ready();
    height: calc(100vh - 120px);
    overflow: scroll;

    @include mq(lg) {
      @include make-col(18);
      @include make-col-offset(3);
      align-items: center;
      display: flex;
      height: 100%;
      overflow: hidden;
    }
  }

  .people-modal-internal-wrapper {
    @include mq(lg) {
      display: flex;
      max-height: 100%;
      position: relative;
    }
  }

  .people-modal-left {
    aspect-ratio: 0.8;
    margin-bottom: pxrem(20);
    max-width: 400px;

    @include mq(lg) {
      @include make-col(7);
      align-items: center;
      aspect-ratio: auto;
      display: flex;
      max-width: 100%;
    }

    .basic-image {
      height: 100%;
    }

    img {
      aspect-ratio: 0.8;
      height: 100%;
      width: 100%;

      @include mq(lg) {
        aspect-ratio: auto;
      }
    }
  }

  .people-modal-right {
    padding-bottom: pxrem(100);
    padding-right: pxrem(40);

    @include mq(lg) {
      @include make-col(9);
      @include make-col-offset(2);
      @include scrollbar;
      overflow-y: auto;
    }
  }

  .people-modal-right-scrollable {
    padding-right: pxrem(30);

    &::after {
      background: linear-gradient(180deg, transparent 0%, $color-blue 100%);
      bottom: 40px;
      content: '';
      display: block;
      height: 20vh;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;

      @include mq(lg) {
        bottom: 0;
        left: col-width(9);
        width: 100%;
      }
    }
  }

  .people-modal-title {
    @include display-3($color-white);
    margin-bottom: pxrem(8);
  }

  .people-modal-role {
    @include paragraph($color-white);
    margin-bottom: pxrem(30);

    @include mq(lg) {
      margin-bottom: pxrem(50);
    }
  }

  .people-modal-bio {
    @include paragraph($color-white);
    margin-bottom: pxrem(30);
  }
}
</style>
