<script setup>
const props = defineProps({
  aspect: {
    type: String,
    default: 'landscape-wide',
    validator(value) {
      return ['landscape-wide', 'landscape-regular'].includes(value)
    },
  },
  content: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <section class="paragraph paragraph--slider">
    <div class="paragraph-wrapper">
      <SharedSlidersFull
        v-if="content && content.images"
        :images="content.images"
        color="dark"
        :aspect="props.aspect" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.paragraph--slider {
  margin-bottom: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(85);
  }

  .paragraph-wrapper {
    margin-bottom: pxrem(45);
  }
}
</style>
