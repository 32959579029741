<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const swiperContainer = ref()

const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   initializeSlider()
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <div
    class="swiper swiper--technical animate-in-bottom animate-out-right"
    :class="props.aspect">
    <swiper-container init="false" ref="swiperContainer" events-prefix="swiper">
      <div slot="container-end">
        <div class="swiper-navigation" v-if="images.length > 1">
          <div class="swiper-button swiper-button-prev sensible">
            <div class="btn btn--round btn--round-dark">
              <svg class="icon icon--btn-round icon--rotate-180">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
          <div class="swiper-button swiper-button-next sensible">
            <div class="btn btn--round btn--round-dark">
              <svg class="icon icon--btn-round">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <swiper-slide class="swiper-slide" v-for="(slide, index) in props.slides">
        <div class="slide-technical">
          <h2 class="slide-title">{{ slide.title }}</h2>
          <div class="slide-subtitle">{{ slide.subtitle }}</div>
          <ul class="slide-list">
            <li class="slide-list-item" v-for="item in slide.list">
              {{ item.text }}
            </li>
          </ul>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style lang="scss">
.swiper--technical {
  .swiper-navigation {
    margin-top: pxrem(15);

    .swiper-button-prev {
      margin-right: pxrem(8);
    }
  }

  .slide-technical {
  }

  swiper-container {
    margin: pxrem(30) pxrem(15);

    @include mq(md) {
      margin: 0;
    }
  }

  .slide-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(40);
  }

  .slide-subtitle {
    @include label;
    margin-bottom: pxrem(40);
  }

  .slide-list {
    @include list-reset;

    .slide-list-item {
      @include label(darken($color-gray-dark, 20%));
      border-bottom: 1px solid darken($color-gray-dark, 10%);
      margin-bottom: pxrem(18);
      padding-bottom: pxrem(18);

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>
