<script setup>
import {reactive} from 'vue'
import {useLocalePath} from 'vue-i18n-routing'
import {register} from 'swiper/element/bundle'
register()

const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const content = reactive(props.content)

const swiperContainer = ref()
const officeDetails = ref([])
const officeMobileList = ref([])

const updateOfficeDetail = (event) => {
  const activeSlide = swiperContainer.value.querySelector(
    '.swiper-slide-active'
  )

  const officeDetailTarget = document.querySelector(
    '.' + activeSlide.dataset.target
  )

  officeDetails.value.forEach((officeDetail) => {
    officeDetail.classList.remove('show')
  })
  officeDetailTarget.classList.add('show')
}

const updateOfficeDetailMobile = (event) => {
  const officeDetailTarget = document.querySelector(
    '.' + event.target.dataset.target
  )

  officeMobileList.value.forEach((officeItem) => {
    officeItem.classList.remove('active')
  })
  event.target.classList.add('active')

  officeDetails.value.forEach((officeDetail) => {
    officeDetail.classList.remove('show')
  })

  if (officeDetailTarget) {
    officeDetailTarget.classList.add('show')
  }
}

const swiperParams = {
  centeredSlides: 'true',
  direction: 'vertical',
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  slidesPerGroup: 1,
  slidesPerView: 'auto',
  spaceBetween: 5,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   initializeSlider()
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section class="section--offices-home offices">
    <div class="offices-wrapper">
      <div class="offices-left">
        <div class="office-left-header">
          <h2 class="office-title">{{ content.title }}</h2>
          <div class="office-cta office-cta--desktop">
            <SharedBasicCta
              v-if="content.cta"
              :label="content.cta.label"
              :url="content.cta.url" />
          </div>
        </div>
        <div class="office-left-body">
          <div class="swiper swiper--office">
            <swiper-container
              init="false"
              ref="swiperContainer"
              events-prefix="swiper"
              @afterinit="updateOfficeDetail"
              @swiperslidechangetransitionend="updateOfficeDetail">
              <div slot="container-end">
                <div class="swiper-navigation">
                  <div class="swiper-button swiper-button-prev sensible">
                    <div class="btn btn--round btn--round-dark">
                      <svg class="icon icon--btn-round icon--rotate-180">
                        <use xlink:href="#icon-arrow"></use>
                      </svg>
                    </div>
                  </div>
                  <div class="swiper-button swiper-button-next sensible">
                    <div class="btn btn--round btn--round-dark">
                      <svg class="icon icon--btn-round">
                        <use xlink:href="#icon-arrow"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <swiper-slide
                class="swiper-slide"
                v-for="(country, index) in content.countries"
                :data-target="'office-detail--' + index">
                <NuxtLink
                  :to="localePath(country.url)"
                  class="cta cta--dark sensible">
                  {{ country.name }}
                  <div class="btn btn--round btn--round-dark">
                    <svg class="icon icon--btn-round">
                      <use xlink:href="#icon-arrow"></use>
                    </svg>
                  </div>
                </NuxtLink>
              </swiper-slide>
            </swiper-container>
          </div>
          <ul class="office-mobile-list">
            <li
              class="office-mobile-list-item"
              :class="[index == 0 ? 'active' : '']"
              v-for="(country, index) in content.countries"
              :key="index"
              :data-target="'office-detail--' + index"
              @click="updateOfficeDetailMobile"
              ref="officeMobileList">
              {{ country.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="offices-right">
        <div
          v-for="(country, index) in content.countries"
          class="office-detail"
          :class="['office-detail--' + index, index == 0 ? 'show' : '']"
          ref="officeDetails">
          <SharedBasicImage
            :assets="country.assets"
            :alternative="country.name"
            class="office-image" />
          <div class="office-detail-info">
            <ul class="office-list">
              <li class="office-list-item" v-for="city in country.cities">
                + {{ city }}
              </li>
            </ul>
            <NuxtLink
              :to="localePath(country.url)"
              class="office-detail-cta cta cta--light">
              {{ $t('discover') }} {{ country.name }}
              <div class="btn btn--round btn--round-light">
                <svg class="icon icon--btn-round">
                  <use xlink:href="#icon-arrow"></use>
                </svg>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div class="office-cta office-cta--mobile">
      <SharedBasicCta
        v-if="content.cta"
        :label="content.cta.label"
        :url="content.cta.url" />
    </div>
  </section>
</template>

<style lang="scss">
.section--offices-home.offices {
  padding-bottom: pxrem(40);

  @include mq(md) {
    padding-bottom: pxrem(100);
  }

  .offices-wrapper {
    @include mq(md) {
      display: flex;
    }
  }

  .offices-left {
    @include make-col-ready;
    padding-bottom: pxrem(40);
    padding-top: pxrem(40);

    @include mq(md) {
      @include make-col(13);
      @include make-col-offset(0.5);
    }

    @include mq(xl) {
      @include make-col-offset(1);
    }

    @include mq(full) {
      padding-bottom: pxrem(90);
      padding-top: pxrem(90);
    }
  }

  .office-left-header {
    align-items: center;
    margin-bottom: pxrem(20);

    @include mq(lg) {
      display: flex;
      justify-content: space-between;
    }
  }

  .office-title {
    @include display-2;
    font-weight: 400;
  }

  .office-cta {
    margin-top: pxrem(6);

    &.office-cta--desktop {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &.office-cta--mobile {
      margin-top: pxrem(30);
      text-align: center;

      @include mq(md) {
        display: none;
      }
    }
  }

  .office-mobile-list {
    @include list-reset;

    @include mq(md) {
      display: none;
    }

    .office-mobile-list-item {
      font-size: pxrem(55);
      font-family: $font-secondary;
      font-weight: 400;
      line-height: 1.25;
      opacity: 1;
      text-transform: uppercase;
      transition: opacity $transition-default;

      @include mq(xs) {
        font-size: pxrem(65);
      }

      @include mq(sm) {
        font-size: pxrem(80);
      }

      // &.active {
      //   opacity: 1;
      // }
    }
  }

  .offices-right {
    aspect-ratio: 1;
    position: relative;

    @include mq(md) {
      aspect-ratio: auto;
      @include make-col(9.5);
      @include make-col-offset(1);
    }

    @include mq(xxl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }

  .office-detail {
    aspect-ratio: 1;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity $transition-default-slow;

    @include mq(md) {
      aspect-ratio: 0.8;
    }

    &.show {
      opacity: 1;
    }
  }

  .office-detail-cta {
    margin-top: pxrem(20);

    @include mq(md) {
      display: none;
    }
  }

  .office-image {
    position: relative;

    &::before {
      background: linear-gradient(
        0deg,
        rgba($color-blue, 0.9) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      bottom: 0;
      content: '';
      display: block;
      height: 70%;
      left: 0;
      position: absolute;
      right: 0;

      @include mq(md) {
        height: 40%;
      }
    }

    &::after {
      background-color: rgba($color-blue, 0.45);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include mq(md) {
        background-color: rgba($color-blue, 0.35);
      }
    }

    img {
      aspect-ratio: 1;
      width: 100%;

      @include mq(md) {
        aspect-ratio: 0.8;
      }
    }
  }

  .office-detail-info {
    bottom: pxrem(30);
    position: absolute;
    left: pxrem(30);
  }

  .office-list {
    @include list-reset;
  }

  .office-list-item {
    @include label($color-white);
    margin-bottom: pxrem(3);
  }
}

.swiper--office {
  display: none;

  @include mq(md) {
    display: block;
  }

  swiper-container {
    height: 40vh;
    padding-left: col-width(1) * 1.5;
    position: relative;
    width: 100%;

    @include mq(lg) {
      height: 50vh;
    }

    @include mq(xxl) {
      height: 60vh;
      padding-left: col-width(2);
    }

    &::before {
      background: linear-gradient(
        180deg,
        rgba($color-white, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      display: block;
      height: 22%;
      left: col-width(1) * 1.5;
      position: absolute;
      top: -3px;
      width: 100%;
      z-index: 2;

      @include mq(xxl) {
        left: col-width(2);
      }
    }

    &::after {
      background: linear-gradient(
        0deg,
        rgba($color-white, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      bottom: -2px;
      content: '';
      display: block;
      height: 22%;
      left: col-width(1) * 1.5;
      position: absolute;
      width: 100%;
      z-index: 1;

      @include mq(xxl) {
        left: col-width(2);
      }
    }
  }

  .swiper-slide {
    pointer-events: none;

    @include mq(md) {
      font-size: $font-xxxlarge;
      height: pxrem(60) !important;
      padding-left: pxrem(10);
    }

    @include mq(int) {
      font-size: $font-xxxlarge;
      height: $font-xxxlarge !important;
    }

    @include mq(lg) {
      padding-left: 0;
    }

    @include mq(xl) {
      font-size: $font-semihuge;
      height: $font-semihuge !important;
    }

    @include mq(xxl) {
      font-size: $font-huge;
      height: $font-huge !important;
    }

    &.swiper-slide-active {
      pointer-events: all;

      .cta {
        font-family: $font-secondary;
        font-weight: 400;
        opacity: 1;

        &:hover {
          .btn {
            opacity: 1;
          }
        }
      }
    }

    &:first-of-type {
      margin-top: 17vh;
    }

    .cta {
      align-items: center;
      display: flex;
      font-size: $font-huge;
      font-weight: 400;
      letter-spacing: -1.5px;
      line-height: 1;
      justify-content: space-between;
      opacity: 0.1;
      transition: opacity $transition-default;
      user-select: none;

      @include mq(md) {
        font-size: pxrem(60);
      }

      @include mq(int) {
        font-size: $font-xxxlarge;
      }

      @include mq(xl) {
        font-size: $font-semihuge;
      }

      @include mq(xxl) {
        font-size: $font-huge;
      }

      .btn {
        opacity: 0;
        transition: all $transition-default;
      }
    }
  }

  .swiper-navigation {
    align-items: center;
    display: flex;
    bottom: 0;
    left: pxrem(10);
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 50vh;

    &::after {
      background-color: $color-gray-light;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
    }

    .swiper-button-next {
      margin-left: pxrem(8);
      margin-right: pxrem(8);
    }
  }
}
</style>
