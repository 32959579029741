<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <section class="paragraph paragraph--slider-technical">
    <div class="paragraph-wrapper">
      <div class="paragraph-left animate-in-bottom animate-out-left">
        <SharedBasicImage
          :assets="props.content.assets"
          class="paragraph-image" />
      </div>
      <div class="paragraph-right">
        <SharedSlidersTechnical :slides="content.slides" />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.paragraph--slider-technical {
  margin-bottom: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(85);
  }

  .paragraph-wrapper {
    @include mq(md) {
      display: flex;
    }
  }

  .paragraph-left {
    @include mq(md) {
      @include make-col(11);
    }
  }

  .paragraph-image {
    img {
      aspect-ratio: 1;
      object-fit: cover;

      @include mq(md) {
        aspect-ratio: 0.7;
      }

      @include mq(lg) {
        aspect-ratio: 0.8;
      }

      @include mq(xl) {
        aspect-ratio: 1;
      }
    }
  }

  .paragraph-right {
    @include mq(md) {
      @include make-col(9);
      @include make-col-offset(2);
      align-items: center;
      display: flex;
      padding-bottom: 4vw;
      padding-top: 4vw;
    }
  }
}
</style>
