<script setup>
import {register} from 'swiper/element/bundle'
register()

const route = useRoute()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const swiperContainer = ref()

const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 480px
    480: {
      slidesPerGroup: 2,
      slidesPerView: 2,
      spaceBetween: 15,
    },
    // when window width is >= 768px
    768: {
      slidesPerGroup: 3,
      slidesPerView: 3,
      spaceBetween: 15,
    },
  },
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   initializeSlider()
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <transition>
    <div>
      <section
        class="projects-selected"
        :class="'layout--' + props.content.layout">
        <div class="projects-selected-wrapper">
          <div class="projects-selected-header animate-in-stagger-left">
            <h2
              class="projects-selected-title animate-stagger-item animate-out-left">
              {{ content.title }}
            </h2>
            <div
              class="projects-selected-cta projects-selected-cta--desktop animate-stagger-item animate-out-right"
              v-if="content.cta">
              <SharedBasicCta
                :label="content.cta.label"
                :url="`/sectors/${route.params.sectorSlug}/portfolio`"
                color="dark" />
            </div>
          </div>
          <div class="projects-selected-list">
            <div
              v-for="(project, index) in content.list"
              :key="index"
              class="projects-selected-item sensible animate-in-bottom"
              :class="{
                'animate-out-right': index % 2 === 1,
                'animate-out-left': index % 2 === 0,
              }">
              <SharedCardsProject
                :title="project.title"
                :awards="project.awards"
                :slug="project.slug"
                :images="project.assets" />
            </div>
          </div>
          <div
            class="swiper swiper--projects animate-in-bottom animate-out-left">
            <swiper-container
              init="false"
              ref="swiperContainer"
              events-prefix="swiper">
              <div slot="container-end">
                <div v-if="content.list.length > 1" class="swiper-navigation">
                  <div class="swiper-button swiper-button-prev sensible">
                    <div class="btn btn--round btn--round-dark">
                      <svg class="icon icon--btn-round icon--rotate-180">
                        <use xlink:href="#icon-arrow"></use>
                      </svg>
                    </div>
                  </div>
                  <div class="swiper-button swiper-button-next sensible">
                    <div class="btn btn--round btn--round-dark">
                      <svg class="icon icon--btn-round">
                        <use xlink:href="#icon-arrow"></use>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination--dark"></div>
              </div>
              <swiper-slide
                class="swiper-slide"
                v-for="project in content.list">
                <SharedCardsProject
                  :title="project.title"
                  :awards="project.awards"
                  :slug="project.slug"
                  :images="project.assets" />
              </swiper-slide>
            </swiper-container>
          </div>
          <div
            class="projects-selected-cta projects-selected-cta--mobile animate-in-bottom animate-out-right"
            v-if="content.cta">
            <SharedBasicCta
              :label="content.cta.label"
              :url="`/sectors/${route.params.sectorSlug}/portfolio`"
              color="dark" />
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<style lang="scss">
.projects-selected.layout--6 {
  border-top: 1px solid $color-gray;
  margin-bottom: pxrem(120);

  .projects-selected-wrapper {
    @include make-col-ready();

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .projects-selected-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .projects-selected-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(45);
    padding-top: pxrem(90);
    text-align: center;
  }

  .projects-selected-cta {
    &.projects-selected-cta--desktop {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &.projects-selected-cta--mobile {
      margin-top: pxrem(30);
      text-align: center;

      @include mq(md) {
        display: none;
      }
    }
  }

  .projects-selected-list {
    display: none;

    @include mq(md) {
      column-gap: pxrem(15);
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .projects-selected-item {
    margin-bottom: pxrem(20);
  }

  .swiper--projects {
    @include mq(md) {
      display: none;
    }

    .swiper-slide {
      height: auto;
      padding-bottom: 1px;
    }

    div[slot='container-end'] {
      // @include make-col-ready();
      display: flex;
      justify-content: space-between;
    }

    .swiper-navigation {
      margin-top: pxrem(15);

      .swiper-button-prev {
        margin-right: pxrem(8);
      }
    }

    .swiper-pagination {
      display: none;

      @include mq(sm) {
        display: block;
        margin-top: pxrem(34);
        width: auto;
      }
    }
  }
}
</style>
