<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const generateSlug = SlugifyUtils()

function openModal(event) {
  const elem = event.target.closest('.people-elem')

  if (elem) {
    const target = document.querySelector(elem.dataset.target)
    target.classList.add('open')
    document.body.classList.add('overflow-y-hidden')
  }
}

const swiperContainer = ref()

const swiperParams = {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <section v-if="content.list.length > 0" class="section section--people">
    <div class="section-wrapper">
      <h2 class="section-title animate-in-bottom animate-out-left">
        {{ $t('pininfarina') }} {{ content.title }}
      </h2>
      <div class="section-list people-list list--desktop">
        <div v-for="(person, index) in content.list">
          <div
            class="people-elem sensible-more animate-in-bottom animate-out-right"
            :data-target="'#' + generateSlug(person.name) + '-' + index"
            @click="openModal">
            <div class="people-image">
              <SharedBasicImage
                :assets="person.assets"
                :alternative="person.name" />
            </div>
            <h3 class="people-name">{{ person.name }}</h3>
            <div class="people-role">{{ person.role }}</div>
          </div>
        </div>
      </div>
      <div
        class="swiper swiper--people list--mobile animate-in-bottom animate-out-left">
        <swiper-container
          init="false"
          ref="swiperContainer"
          events-prefix="swiper">
          <div slot="container-end">
            <div v-if="content.list.length > 1" class="swiper-navigation">
              <div class="swiper-button swiper-button-prev sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round icon--rotate-180">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
              <div class="swiper-button swiper-button-next sensible">
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <swiper-slide
            class="swiper-slide"
            v-for="(person, index) in content.list">
            <div
              class="people-elem sensible-more"
              :data-target="'#' + generateSlug(person.name) + '-' + index"
              @click="openModal">
              <div class="people-image">
                <SharedBasicImage
                  :assets="person.assets"
                  :alternative="person.name" />
              </div>
              <h3 class="people-name">{{ person.name }}</h3>
              <div class="people-role">{{ person.role }}</div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
      <div v-for="(person, index) in content.list">
        <AboutPeopleModal
          :id="generateSlug(person.name) + '-' + index"
          :content="person" />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section--people {
  margin-bottom: pxrem(45);
  margin-top: pxrem(45);

  @include mq(md) {
    margin-bottom: pxrem(100);
    margin-top: pxrem(100);
  }

  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .list--desktop {
    display: none;

    @include mq(md) {
      display: grid;
    }
  }

  .list--mobile {
    @include mq(md) {
      display: none;
    }
  }

  .people-list {
    @include mq(md) {
      column-gap: pxrem(12);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: pxrem(90);
    }
  }

  .people-elem {
    margin-bottom: pxrem(35);
    @include mq(md) {
      margin-bottom: 0;
    }

    &:hover {
      .people-image {
        img {
          transform: scale(1.025);
        }
      }
    }
  }

  .people-image {
    aspect-ratio: 0.68;
    margin-bottom: pxrem(20);
    overflow: hidden;

    @include mq(md) {
      aspect-ratio: 0.8;
    }

    img {
      aspect-ratio: 0.68;
      transition: transform $transition-default;
      width: 100%;
      will-change: transition;

      @include mq(md) {
        aspect-ratio: 0.8;
      }
    }
  }

  .people-name {
    @include display-3;
    margin-bottom: pxrem(8);
  }

  .people-role {
    @include paragraph;
  }

  .swiper--people {
    .swiper-navigation {
      margin-top: pxrem(15);

      .swiper-button-prev {
        margin-right: pxrem(8);
      }
    }
  }
}
</style>
