<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

// get route parameters
const route = useRoute()

onMounted(() => {
  const page = route.name.substring(0, route.name.indexOf('__'))
  if (page == 'about-sustainability') {
    const paragraph = document.querySelector(
      '.main-content-full > div:first-of-type .paragraph'
    )
    paragraph.classList.add('top-padding')
  }
})
</script>

<template>
  <section class="paragraph paragraph--screamer-text">
    <div class="paragraph-wrapper">
      <div class="paragraph-left animate-in-stagger-bottom animate-out-left">
        <h2
          class="paragraph-screamer animate-stagger-item"
          v-if="content.screamer"
          v-html="content.screamer"></h2>
        <div
          class="paragraph-author-name animate-stagger-item"
          v-if="content.author"
          v-html="content.author.name"></div>
        <div
          class="paragraph-author-role animate-stagger-item"
          v-if="content.author"
          v-html="content.author.role"></div>
      </div>
      <div class="paragraph-right animate-in-bottom animate-out-right">
        <div
          class="paragraph-text"
          v-if="content.text"
          v-html="content.text"></div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.paragraph--screamer-text {
  margin-bottom: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(85);
  }

  &.top-padding {
    padding-top: pxrem(60);

    @include mq(md) {
      padding-top: pxrem(120);
    }
  }

  .paragraph-wrapper {
    @include mq(md) {
      display: flex;
    }
  }

  .paragraph-left {
    margin-bottom: pxrem(55);

    @include mq(md) {
      @include make-col(8);
    }
  }

  .paragraph-right {
    @include mq(md) {
      @include make-col(12);
      @include make-col-offset(2);
    }
  }

  .paragraph-screamer {
    @include display-3;
    font-weight: 400;
    margin-bottom: pxrem(20);
  }

  .paragraph-author-name {
    @include label;
    font-weight: 700;
    margin-bottom: pxrem(3);
  }

  .paragraph-author-role {
    @include label;
    letter-spacing: 0.5px;
    margin-bottom: pxrem(3);
  }

  .paragraph-text {
    @include paragraph;
    font-weight: 400;
    margin-bottom: pxrem(50);

    @include mq(md) {
      margin-bottom: pxrem(70);
    }

    > p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
