<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

function toggleAccordion(event) {
  const accordion = event.target.closest('.accordion-elem')
  const accordionBody = accordion.querySelector('.accordion-body')
  const height = accordionBody.scrollHeight

  if (!accordion.classList.contains('open')) {
    // if closed -> open accordion
    accordion.classList.remove('is-hidden')
    accordionBody.style.maxHeight = `${0}px`
    setTimeout(() => {
      accordionBody.style.maxHeight = `${height}px`
    }, 1)
  } else {
    // if open -> close accordion
    accordionBody.style.maxHeight = `${height}px`
    setTimeout(() => {
      accordionBody.style.maxHeight = `${0}px`
    }, 1)
  }

  accordion.classList.toggle('open')

  // emit('accordion-clicked')
}
</script>

<template>
  <section class="section section--accordion">
    <div class="accordion-wrapper">
      <div class="accordion-list">
        <div
          class="accordion-elem is-hidden"
          v-for="(accordion, index) in content">
          <div class="accordion-elem-header sensible" @click="toggleAccordion">
            <h3 class="accordion-elem-title">
              {{ accordion.title }}
            </h3>
            <div class="accordion-icon"></div>
          </div>
          <div class="accordion-body">
            <div class="download-element-list-wrapper">
              <div class="download-element" v-for="document in accordion.list">
                <a class="download-link sensible" :href="document.file">
                  <div class="download-link-left">
                    <div class="btn btn--round btn--round-dark">
                      <svg class="icon icon--btn-round">
                        <use xlink:href="#icon-mail"></use>
                      </svg>
                    </div>
                  </div>
                  <div class="download-link-right">
                    <div class="document-title">{{ document.title }}</div>
                    <div class="document-date">{{ document.date }}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section--accordion {
  .accordion-elem {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-gray;
    }

    &.open {
      .accordion-icon {
        &::after {
          transform: translate(-50%, -50%);
        }
      }

      .accordion-body {
        max-height: 2000px;
      }
    }

    &.is-hidden {
      .accordion-body {
        opacity: 0;
        position: absolute;
        visibility: hidden;
      }
    }
  }

  .accordion-elem-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: pxrem(20);
    padding-top: pxrem(20);

    &:hover {
      .accordion-icon {
        background-color: $color-blue;

        &::before,
        &::after {
          background-color: $color-white;
        }
      }
    }
  }

  .accordion-elem-title {
    @include display-3;
  }

  .accordion-icon {
    border: 1px solid $color-gray-blue;
    border-radius: 50%;
    height: pxrem(40);
    position: relative;
    transition: background-color $transition-default;
    width: pxrem(40);

    &::before,
    &::after {
      background-color: $color-blue;
      content: '';
      display: block;
      height: pxrem(1);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity $transition-default, transform $transition-default;
      width: pxrem(18);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  .accordion-body {
    overflow: hidden;
    transition: max-height $transition-default 120ms;

    @include mq(md) {
      display: flex;
    }
  }

  .accordion-body-left {
    @include mq(md) {
      @include make-col(9);
    }
  }

  .accordion-subtitle {
    @include display-3;
    margin-bottom: pxrem(15);
  }

  .accordion-body-right {
    margin-bottom: pxrem(30);
    @include mq(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }

  .accordion-text {
    margin-bottom: pxrem(10);

    p {
      @include paragraph;
      margin-bottom: pxrem(16);
    }
  }

  .download-element {
    margin-bottom: pxrem(30);
  }

  .download-link {
    display: flex;
    text-decoration: none;

    &:hover {
      .btn {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-white;

        svg {
          @include icon-color($color: $color-white);
        }
      }
    }
  }

  .download-link-left {
    flex-shrink: 0;

    .btn {
      margin-top: pxrem(5);
    }
  }

  .download-link-right {
    margin-left: pxrem(10);
    flex-grow: 1;
  }

  .document-title {
    @include paragraph;
    font-weight: 600;
  }

  .document-date {
    @include paragraph;
  }
}
</style>
