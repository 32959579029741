<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <section class="section section--offices-list">
    <div class="section-wrapper">
      <div
        class="country-section animate-in-fade animate-out-fade"
        v-for="country in content.countries">
        <div class="left-wrapper">
          <div class="country-title animate-in-bottom animate-out-left">
            {{ country.label }}
          </div>
        </div>
        <div class="right-wrapper">
          <div
            class="country-office animate-in-bottom animate-out-left"
            v-for="office in country.offices">
            <div class="office-title">
              {{ office.place }}
            </div>
            <div class="office-type">
              {{ office.type }}
            </div>
            <div
              class="office-body"
              v-if="office.body"
              v-html="office.body"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section--offices-list {
  margin-top: pxrem(50);

  @include mq(md) {
    margin-top: pxrem(120);
  }
  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .country-section {
    border-bottom: 1px solid $color-gray;
    margin-bottom: pxrem(30);
    padding-bottom: pxrem(40);

    &:first-of-type {
      border-top: 1px solid $color-gray;
      padding-top: pxrem(30);
    }

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }

    @include mq(md) {
      display: flex;
      padding-bottom: pxrem(160);

      &:first-of-type {
        border-top: none;
        padding-top: 0;
      }
    }
  }

  .left-wrapper {
    @include mq(md) {
      @include make-col(5);
    }
  }

  .country-title {
    @include display-2;
    margin-bottom: pxrem(20);
    text-transform: capitalize;
  }

  .right-wrapper {
    @include mq(md) {
      @include make-col(15);
      @include make-col-offset(2);
      display: grid;
      column-gap: col-width(1);
      grid-template-columns: 1fr 1fr;
    }

    @include mq(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .country-office {
    margin-bottom: pxrem(30);
  }

  .office-title {
    @include display-3;
    font-weight: 700;
  }

  .office-type {
    @include display-3;
  }
}
</style>
