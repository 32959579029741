<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

// get route parameters
const route = useRoute()

onMounted(() => {
  const page = route.name.substring(0, route.name.indexOf('__'))
  if (page == 'investor-relations') {
    const paragraph = document.querySelector(
      '.main-content-full > div:first-of-type .paragraph'
    )
    paragraph.classList.add('top-padding')
  }
})
</script>

<template>
  <transition>
    <section class="paragraph paragraph--pretitle-title-text">
      <div class="paragraph-wrapper">
        <div
          class="paragraph-wrapper-left animate-in-stagger-bottom animate-out-left">
          <div
            class="paragraph-pretitle animate-stagger-item"
            v-if="content.pretitle"
            v-html="content.pretitle"></div>
          <h2
            class="paragraph-title animate-stagger-item"
            v-if="content.title"
            v-html="content.title"></h2>
        </div>
        <div class="paragraph-wrapper-right">
          <div
            class="paragraph-text animate-in-bottom animate-out-right"
            v-if="content.text"
            v-html="content.text"></div>
        </div>
      </div>
    </section>
  </transition>
</template>

<style lang="scss">
.paragraph--pretitle-title-text {
  margin-bottom: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(85);
  }

  &.top-padding {
    padding-top: pxrem(60);

    @include mq(md) {
      padding-top: pxrem(120);
    }
  }

  .paragraph-wrapper {
    @include mq(md) {
      display: flex;
    }
  }

  .paragraph-wrapper-left {
    @include mq(md) {
      @include make-col(5);
    }
  }

  .paragraph-wrapper-right {
    @include mq(md) {
      @include make-col(12);
      @include make-col-offset(2);
    }
  }

  .paragraph-pretitle {
    @include label;
    margin-bottom: pxrem(8);
  }

  .paragraph-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(30);

    @include mq(md) {
      margin-bottom: pxrem(40);
    }
  }

  .paragraph-text {
    @include paragraph;
    font-weight: 400;
    margin-bottom: pxrem(50);

    @include mq(md) {
      margin-bottom: pxrem(70);
    }

    p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
