<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const nuxtApp = useNuxtApp()

const content = reactive(props.content)

const swiperContainer = ref()

const swiperParams = {
  autoplay: {
    delay: 8000,
  },
  direction: 'vertical',
  effect: 'fade',
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
  slidesPerView: 1,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <transition>
    <section class="banner animate-in-bottom animation-out-left">
      <div class="banner-wrapper">
        <div class="swiper swiper--banner">
          <client-only>
            <swiper-container
              init="false"
              ref="swiperContainer"
              events-prefix="swiper">
              <div slot="container-end">
                <div
                  class="swiper-pagination swiper-pagination--light swiper-pagination--light"></div>
              </div>
              <swiper-slide
                class="swiper-slide"
                v-for="(banner, index) in content.list">
                <SharedCardsBanner
                  :title="banner.title"
                  :text="banner.text"
                  :cta="banner.cta"
                  :images="banner.assets"
                  :layout="content.layout"
                  :modal="banner.modal" />
              </swiper-slide>
            </swiper-container>
          </client-only>
        </div>
      </div>
    </section>
  </transition>
</template>

<style lang="scss">
.banner {
  margin-bottom: pxrem(60);

  @include mq(lg) {
    margin-bottom: pxrem(120);
  }

  .banner-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }
}

.swiper--banner {
  position: relative;

  swiper-container {
    height: pxrem(290) !important;
  }

  .swiper-slide {
    height: pxrem(290) !important;
    object-fit: cover;

    &::after {
      background: linear-gradient(
        90deg,
        rgba($color-blue, 0.7) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 10%;
      z-index: 1;
    }

    .card-content{
      z-index: 2;
    }

    .card-image {
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .swiper-pagination {
    bottom: pxrem(40);
    left: pxrem(24);
    position: absolute;
    right: auto;
    top: auto;
    transform: none;
  }
}
</style>
