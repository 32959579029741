<script setup>
import {inject} from 'vue'
import {reactive} from 'vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const sectorsDetail = ref([])
const sectorsListItems = ref([])

const processedDataMenu = inject('processedDataMenu')
const internalMenuNav = getInternalNavMenu(processedDataMenu?.value, 'sectors')

const sectorsTabsContent = internalMenuNav.menu_items.map((menuItem) => {
  const matchingSector = props.content.sectors.find(
    (item) => item.slug === menuItem.slug
  )
  return matchingSector
    ? {
        ...menuItem,
        text: matchingSector.text,
        cta: {
          label: matchingSector.cta.label,
          path: menuItem.path,
        },
      }
    : menuItem
})

const selectedPattern = ref('')
selectedPattern.value = sectorsTabsContent[0].slug

function showSector(event) {
  // update pattern
  selectedPattern.value = event.target.dataset.pattern

  // update element
  const sectorElem = document.querySelector('.' + event.target.dataset.target)

  if (sectorElem) {
    sectorsDetail.value.forEach((detail) => {
      detail.classList.remove('show')
    })
    sectorElem.classList.add('show')
    sectorsListItems.value.forEach((item) => {
      item.classList.remove('active')
    })
    event.target.classList.add('active')
  }
}
</script>

<template>
  <section class="paragraph--sectors-home sectors">
    <div class="sectors-wrapper">
      <div class="sectors-left">
        <div
          v-for="(sector, index) in sectorsTabsContent"
          class="sector-detail"
          :class="['sector-detail--' + index, index == 0 ? 'show' : '']"
          ref="sectorsDetail">
          <div class="sector-pretitle sector-pretitle--desktop">
            {{ content.title }}
          </div>
          <h2 class="sector-title">{{ sector.label }}</h2>
          <div class="sector-text">
            <p v-html="sector.text"></p>
          </div>
          <div class="sector-cta">
            <SharedBasicCta
              :label="sector.cta.label"
              :url="sector.cta.path"
              color="light" />
          </div>
        </div>
        <SharedPattern
          class="sector-pattern animate-out-left"
          :sector="selectedPattern"
          type="home"
          v-if="true" />
      </div>
      <div class="sectors-right">
        <div class="sectors-blob">
          <SharedBlob />
        </div>
        <div class="sector-pretitle sector-pretitle--mobile">
          {{ content.title }}
        </div>
        <ul class="sectors-list" style="--total: 6">
          <li
            v-for="(sector, index) in sectorsTabsContent"
            class="sectors-list-item sensible"
            :class="{active: index == 0}"
            :style="'--i: ' + (index + 1)"
            :data-target="'sector-detail--' + index"
            :data-pattern="sector.slug"
            ref="sectorsListItems"
            @click="showSector">
            {{ sector.label }}
            <div class="btn btn--round-small btn--round-light">
              <svg class="icon icon--btn-round-tiny">
                <use xlink:href="#icon-dot"></use>
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.sectors {
  background-color: $color-blue;
  margin-bottom: pxrem(50);
  margin-top: pxrem(-240);
  padding-bottom: pxrem(120);
  padding-top: pxrem(240);

  @include mq(md) {
    margin-bottom: pxrem(80);
    padding-bottom: pxrem(220);
  }

  @include mq(md) {
    padding-bottom: pxrem(190);
    padding-top: pxrem(280);
  }

  .sectors-wrapper {
    @include make-col-ready;
    display: flex;
    flex-direction: column-reverse;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      align-items: center;
      flex-direction: row;
    }

    @include mq(lg) {
      @include make-col(19);
      @include make-col-offset(3);
    }
  }

  .sectors-left {
    position: relative;

    @include mq(md) {
      @include make-col(9);
      aspect-ratio: 1;
    }

    @include mq(lg) {
      @include make-col(7);
    }
  }

  .sector-detail {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $transition-default;

    @include mq(md) {
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
    }

    &.show {
      opacity: 1;
      pointer-events: all;
      position: relative;

      @include mq(md) {
        position: absolute;
      }
    }
  }

  .sector-pretitle {
    @include display-3($color-white);
    margin-bottom: pxrem(30);

    &.sector-pretitle--desktop {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &.sector-pretitle--mobile {
      margin-bottom: pxrem(35);

      @include mq(md) {
        display: none;
      }
    }
  }

  .sector-title {
    @include display-2($color-white);
    font-family: $font-secondary;
    font-weight: 400;
    margin-bottom: pxrem(14);
  }

  .sector-text {
    @include paragraph-big($color-white);
    margin-bottom: pxrem(12);
    margin-top: 0;
  }

  .sector-pattern {
    bottom: -12vh;
    position: absolute;
    right: 0;

    @include mq(md) {
      bottom: auto;
      left: 0;
      right: auto;
      top: 2rem;
    }
  }

  .sectors-right {
    position: relative;

    @include mq(md) {
      @include make-col(9);
      @include make-col-offset(3);
    }

    @include mq(lg) {
      @include make-col(8);
      @include make-col-offset(4);
    }
  }

  .sectors-blob {
    position: absolute;
    top: 0;
    transform: translate(20vw, -25%);

    @include mq(md) {
      position: relative;
      transform: none;
    }
  }

  .sectors-list {
    @include list-reset;
    margin-bottom: pxrem(110);

    @include mq(md) {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      // circle placement properties
      border-radius: 50%;
      display: grid;
      grid-template-areas: 'layer';
      place-items: center;

      --radius: 50%;
      height: calc(2 * var(--radius));
      width: calc(2 * var(--radius));
    }
  }

  .sectors-list-item {
    @include label-big($color-white);
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: pxrem(16);
    opacity: 0.3;
    transition: opacity $transition-default;
    user-select: none;

    @include mq(md) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0;

      // circle placement properties
      border-radius: 50%;
      display: grid;
      grid-area: layer;
      height: 3vw;
      place-items: center;
      width: 35vw;

      // Decimal value, 0-1 based on the child's index.
      --d: calc(var(--i) / var(--total));
      // Offset to get better starting placement on the circle
      --r-offset: -0.25turn;
      // Full circle
      --r-amount: 1turn;
      // Rotation based on the decimal and r modifiers
      --r: calc((var(--r-amount) * var(--d)) + var(--r-offset));
      // Rotate, transform out, then negative rotation so the content appears upright
      --transform: rotate(var(--r)) translate(var(--radius))
        rotate(calc(-1 * var(--r)));
      transform: var(--transform);
    }

    @include mq(lg) {
      width: 30vw;
    }

    &:hover,
    &.active {
      opacity: 1;
    }

    > div {
      margin-right: pxrem(8);
      pointer-events: none;

      @include mq(md) {
        margin-right: 0;
        margin-top: pxrem(8);
      }
    }
  }
}
</style>
