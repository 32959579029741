<script setup>
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  aspect: {
    type: String,
    default: 'landscape-wide',
    validator(value) {
      return ['landscape-wide', 'landscape-regular'].includes(value)
    },
  },
  images: {
    type: Array,
    required: true,
  },
  color: {
    type: String,
    default: 'dark',
    validator(value) {
      return ['dark', 'light'].includes(value)
    },
  },
})

const nuxtApp = useNuxtApp()

const images = reactive(props.images)

const swiperContainer = ref()

const swiperParams = {
  effect: 'fade',
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }
})

// nuxtApp.hook('page:transition:finish', () => {
//   initializeSlider()
// })

onBeforeUnmount(() => {
  if (swiperContainer.value && swiperContainer.value.swiper) {
    swiperContainer.value.swiper.destroy()
  }
})
</script>

<template>
  <div
    class="swiper swiper--full-size animate-in-fade animate-out-fade"
    :class="props.aspect">
    <swiper-container init="false" ref="swiperContainer" events-prefix="swiper">
      <div slot="container-end">
        <div class="swiper-navigation" v-if="images.length > 1">
          <div class="swiper-button swiper-button-prev sensible">
            <div class="btn btn--round" :class="'btn--round-' + props.color">
              <svg class="icon icon--btn-round icon--rotate-180">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
          <div class="swiper-button swiper-button-next sensible">
            <div class="btn btn--round" :class="'btn--round-' + props.color">
              <svg class="icon icon--btn-round">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <swiper-slide class="swiper-slide" v-for="image in images">
        <SharedBasicImage
          :assets="image.assets"
          :alternative="image.alternative"
          class="swiper-slide-image" />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style lang="scss">
.swiper--full-size {
  .swiper-slide-image {
    img {
      aspect-ratio: 0.7;
      width: 100%;

      @include mq(md) {
        aspect-ratio: 2.35;
      }
    }
  }

  .swiper-navigation {
    margin-top: pxrem(15);

    .swiper-button-prev {
      margin-right: pxrem(8);
    }
  }

  &.landscape-regular {
    .swiper-slide-image {
      img {
        aspect-ratio: 1.3;

        @include mq(md) {
          aspect-ratio: 1.5;
        }
      }
    }
  }
}
</style>
