<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <section class="section section--newsletters">
    <div class="section-wrapper">
      <h2 class="section-title">
        {{ props.content.title }}
      </h2>
      <div class="section-content">
        <div class="section-accordion-wrapper">
          <MediaHubNewsletterAccordion :content="props.content.accordion" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section--newsletters {
  border-top: 1px solid $color-gray;

  .section-wrapper {
    @include make-col-ready();
    padding-bottom: pxrem(60);
    padding-top: pxrem(60);

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      padding-bottom: pxrem(120);
      padding-top: pxrem(120);
    }
  }

  .section-title {
    @include display-2;
    font-weight: 400;
  }

  .section-accordion-wrapper {
    margin-top: pxrem(35);

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(1);
      margin-top: pxrem(50);
    }
  }
}
</style>
