<script setup>
const props = defineProps({
  title: {
    type: String,
  },
  text: {
    type: String,
  },
  images: {
    type: Array,
    required: true,
  },
  cta: {
    type: Object,
  },
  layout: {
    type: Number,
    default: 1,
    validator(value) {
      return value >= 1 && value <= 2
    },
  },
  modal: {
    type: Boolean,
    default: false,
  },
})

const {$event} = useNuxtApp()

const openProperModal = (signal) => {
  // emit signal to open proper modal on shared bus
  $event(signal)
}
</script>

<template>
  <div class="card-banner" :class="['layout--' + props.layout]">
    <SharedBasicImage
      :assets="props.images"
      :animate="false"
      class="card-image" />
    <div class="card-content">
      <div class="card-title" v-if="props.title">
        {{ props.title }}
      </div>
      <div class="card-text" v-if="props.text">
        <p v-html="props.text"></p>
      </div>
      <div class="card-cta" v-if="props.cta">
        <template v-if="!props.modal">
          <!-- If props.cta.modal is false -->
          <SharedBasicCta
            v-if="props.cta"
            :label="props.cta.label"
            :url="props.cta.url"
            color="light" />
        </template>
        <template v-else>
          <div
            class="cta sensible cta--light"
            @click="openProperModal('modal:newsletter')">
            {{ props.cta.label }}
            <div class="menu-link-btn btn btn--round-small btn--round-light">
              <svg class="icon icon--btn-round-small">
                <use xlink:href="#icon-arrow"></use>
              </svg>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.card-banner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: pxrem(20);
  position: relative;

  @include mq(md) {
    padding: pxrem(64) pxrem(30) pxrem(30);
  }

  .card-image {
    bottom: 0;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;

    &::after {
      background-color: rgba($color-blue, 0.4);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .card-content {
    margin-left: 12%;
    position: relative;
    z-index: 1;

    @include mq(md) {
      margin-left: 0;
      text-align: center;
    }
  }

  .card-title {
    @include display-2($color-white);
    font-family: $font-secondary;
    font-weight: 400;
  }

  .card-text {
    max-width: pxrem(450);

    p {
      @include display-2($color-white);
      margin-bottom: pxrem(50);
    }
  }

  &.layout--2 {
    justify-content: flex-start;
    padding: pxrem(20);

    @include mq(md) {
      padding: pxrem(30) pxrem(80);
    }

    .card-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-align: left;
    }

    .card-title {
      font-family: $font-secondary;
      font-weight: 400;
    }

    .card-text {
      max-width: 100%;
      margin-top: auto;

      p {
        @include display-3($color-white);
        margin-bottom: pxrem(10);
      }
    }

    .card-cta {
      margin-top: auto;
    }
  }
}
</style>
